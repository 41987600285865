<template>
    <div class="virtual_production_videos" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0s">
        <!-- 第一行大视频 -->
        <div v-show="videoListLineOne.length > 0" class="videolist_lineone" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0.4s">
            <template v-for='(item, index) in videoListLineOne'>
                <div v-bind:key='item.videoId' class="videolist_lineone_item" :class="index > 2 ? 'small' : ''" @mouseout="stopPlay(index)" @mouseover='playVideo(index)' @click='gotoDetail(item)'>
                    <video referrerpolicy='no-referrer' class='video1' muted loop v-show='item.showVideo' :src="item.videoUrl"></video>
                    <div class="cover1" v-show='!item.showVideo'>
                        <img referrerpolicy='no-referrer' :src="item.coverUrl" alt="" class='poster'>
                        <img :src="require('@/assets/imgs/play.png')" alt="" class="play_btn">
                    </div>
                    <div class='pic_list_item_title paragraph' v-html="item.introduction"></div>
                </div>
            </template>
        </div>
        <!-- 第二行小视频 -->
        <div v-show="videoListLineTwo.length > 0" class="videolist_linetwo" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0.6s">
            <el-carousel :interval="10000" ref="cardShow" indicator-position="outside" arrow="never" class="video_list">
                <el-carousel-item v-for="innerList, index in videoListLineTwo" :key="`out${index}`">
                    <template v-for='(item, innerIndex) in innerList'>
                        <div v-bind:key='item.videoId' class="video_list_item" @mouseout="stopPlay2(index, innerIndex)" @mouseover='playVideo2(index, innerIndex)' @click='gotoDetail(item)'>
                            <video referrerpolicy='no-referrer' class='video2' muted loop v-show='item.showVideo' :src="item.videoUrl"></video>
                            <div class="cover2" v-show='!item.showVideo'>
                                <img referrerpolicy='no-referrer' :src="item.coverUrl" alt="" class='poster'>
                                <img :src="require('@/assets/imgs/play.png')" alt="" class="play_btn">
                            </div>
                            <div class='pic_list_item_title2 paragraph'>{{item.introduction}}</div>
                        </div>
                    </template>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>
<script>
import imagePreview from 'image-preview-vue'
import 'image-preview-vue/lib/imagepreviewvue.css'
import * as configApi from '@/api/config.js'
import { mapGetters } from 'vuex'
export default {
    data: () => {
        return {
            videoListLineOne: [],
            videoListLineTwo: [],
            videos1Arr: '',
            videos2Arr: '',
            configData: {}
        }
    },
    computed: {
        ...mapGetters(['language']),
        queryType () {
            return this.language === 'zh-Cn' ? 5 : 6
        }
    },
    watch: {
        language: {
            handler (n, o) {
                this.getConfigQuery()
            },
            deep: true
        }
    },
    created () {
        this.getConfig()
    },
    methods: {
        getConfig () {
            configApi.getConfigQuery(5).then(res => {
                if (res.code === 'A00000') {
                    this.configData[5] = res.data
                    if (this.queryType === 5) {
                        this.getConfigQuery()
                    }
                }
            })
            configApi.getConfigQuery(6).then(res => {
                if (res.code === 'A00000') {
                    this.configData[6] = res.data
                    if (this.queryType === 6) {
                        this.getConfigQuery()
                    }
                }
            })
        },
        chunk (array, size = 1) {
            if (!array || !Array.isArray(array) || array.length <= 0) {
                return []
            } else {
                const result = []
                var i = 0
                while (true) {
                    result.push(array.slice(i, size + i))
                    i += size
                    if (i >= array.length) {
                        break
                    }
                }
                return result
            }
        },
        getConfigQuery () {
            if (this.configData[this.queryType]) {
                this.videoListLineOne = []
                this.videoListLineTwo = []
                this.configData[this.queryType].forEach((item, ind) => {
                    item.showVideo = false
                })
                this.videoListLineOne = this.configData[this.queryType].slice(0, 3)
                this.videoListLineTwo = this.chunk(this.configData[this.queryType].slice(3), 4)
            }
        },
        // 第一行大视频hover播放
        playVideo (index) {
            this.videos1Arr = this.videos1Arr ? this.videos1Arr : document.getElementsByClassName('video1')
            if (this.videos1Arr[index] && this.videos1Arr[index].paused) {
                this.videos1Arr[index].currentTime = 0
                this.videoListLineOne[index].showVideo = true
                setTimeout(() => {
                    this.videos1Arr[index].play()
                }, 100)
            }
        },
        stopPlay (index) {
            this.videos1Arr[index].pause()
            this.videoListLineOne[index].showVideo = false
        },
        // 第二行小视屏hover播放
        playVideo2 (index, innerIndex) {
            this.videos2Arr = this.videos2Arr ? this.videos2Arr : document.getElementsByClassName('video2')
            const num = index * 4 + innerIndex
            if (this.videos2Arr[num] && this.videos2Arr[num].paused) {
                this.videos2Arr[num].currentTime = 0
                this.videoListLineTwo[index][innerIndex].showVideo = true
                setTimeout(() => {
                    this.videos2Arr[num].play()
                }, 100)
            }
        },
        stopPlay2 (index, innerIndex) {
            const num = index * 4 + innerIndex
            this.videoListLineTwo[index][innerIndex].showVideo = false
            this.videos2Arr[num].pause()
        },
        gotoDetail (item) {
            // this.$router.push('/video2?videoId=' + item.videoId)
            window.open('/video?id=' + item.id + '&queryType=' + this.queryType, '_blank')
        },
        applyImg (data, index) {
            const preview = imagePreview({
                initIndex: index,
                isEnableBlurBackground: true, // 高斯模糊背景
                // maskBackgroundColor: 'rgba(0,0,0,1)',
                wheelScrollDeltaRatio: 0,
                // isEnableKeyboardShortcuts: false, 快捷键
                containScale: 1,
                isEnableDownloadImage: false,
                images: data.map(item => item.coverUrl)
            })
            console.log(preview)
        }
    }
}
</script>
<style lang="scss" scoped>
.virtual_production_videos{
        padding-bottom: 108px;
        .videolist_lineone{
            width: 1200px;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            box-sizing: border-box;
            .videolist_lineone_item{
                width: 384px;
                display: flex;
                flex-direction: column;
                // margin-bottom: 50px;
                .video1, .cover1{
                    width: 384px;
                    height: 215px;
                    position: relative;
                }
                video, img{
                    width: 100%;
                    height: 100%;
                }
                .play_btn{
                    width: 56px;
                    height: 56px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                .pic_list_item_title{
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    letter-spacing: 0.15em;
                    color: #fff;
                    margin-top: 16px;
                }
            }
            .videolist_lineone_item + .videolist_lineone_item{
                margin-left: 24px;
            }
        }
        .videolist_linetwo{
                display: flex;
                width: 1200px;
                margin: 80px auto 0;
                .video_list{
                    width: 1200px;
                    height: auto;
                    box-sizing: border-box;
                    ::v-deep .el-carousel__container{
                        height: 240px!important;
                    }
                    ::v-deep .el-carousel__item{
                        display: flex;
                        .video_list_item{
                            width: 282px;
                            display: flex;
                            flex-direction: column;
                            .video2, .cover2{
                                width: 282px;
                                height: 158px;
                                position: relative;
                            }
                            video, img{
                                width: 100%;
                                height: 100%;
                            }
                            .play_btn{
                                width: 56px;
                                height: 56px;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                            }
                            .pic_list_item_title2{
                                font-size: 16px;
                                line-height: 22px;
                                text-align: center;
                                letter-spacing: 0.15em;
                                color: #fff;
                                margin-top: 16px;
                            }
                        }
                        .video_list_item + .video_list_item{
                            margin-left: 24px;
                        }
                    }
                }
            }
        }
</style>

<template>
    <div class="virtual_production_header" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0s">
        <div class="logo" @click="refresh">
            <img v-if="language==='en'" :src="require('@/assets/imgs/indexV2/logo_iqiyi_en.png')" alt="">
            <img v-if="language==='zh-Cn'" :src="require('@/assets/imgs/indexV2/logo_iqiyi.png')" alt="">
        </div>
        <div class="menus">
            <a class="menu-item" :class="{'active': item.id === hoverId}" v-for="(item, index) in menus" :key="`menu${item.id} + ${index}`" :href="`#${item.id}`" @mouseover="hoverId = item.id" @mouseout="hoverId = ``">{{language === 'zh-Cn' ? item.label : item.labelEn}}</a>
        </div>
        <div class="language">
            <i v-if="language==='en'" class="iconfont icon-web_zhong" @click="switchLanguage='zh-Cn';handleChangeLanguage()"></i>
            <i v-if="language==='zh-Cn'" class="iconfont icon-web_ying" @click="switchLanguage='en';handleChangeLanguage()"></i>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data: () => {
        return {
            menus: [
                { id: 'news', label: '动态更新', labelEn: 'Updates' },
                { id: 'workspace', label: '摄影棚', labelEn: 'Studio' },
                { id: 'digital_asset_library', label: '数字资产库', labelEn: 'Digital Asset Library' },
                { id: 'contract', label: '联系我们', labelEn: 'Contact Us' }
            ],
            hoverId: '',
            switchLanguage: 'en'
        }
    },
    computed: {
        ...mapGetters(['language'])
    },
    methods: {
        refresh () {
            location.replace(location.origin)
        },
        handleChangeLanguage () {
            localStorage.setItem('vplab_language', this.switchLanguage)
            this.$store.commit('SET_LANGUAGE', this.switchLanguage)
        }
    }
}
</script>
<style lang="scss" scoped>
.virtual_production_header{
        padding: 24px 28px;
        position: sticky;
        top: 0;
        z-index: 999;
        background: #000;
        &:after{
            clear: both;
        }
        .logo{
            display: inline-block;
            width: 187px;
            height: 32px;
            vertical-align: middle;
            img{
                height: 100%;
            }
        }
        .menus{
            display: inline-flex;
            margin-left: 230px;
            .menu-item{
                margin-right: 100px;
                color: #FFFFFF;
                opacity: 0.8;
                font-size: 16px;
                text-align: center;
                cursor: pointer;
                position: relative;
                display: block;
                &:before{
                    position: absolute;
                    visibility: hidden;
                    left: 0;
                    bottom: -6px;
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    content: "";
                    transform: scaleX(0);
                    transition: all .2s ease-in-out 0s;
                    opacity: .85
                }
                &.active:before{
                    visibility: visible;
                    transform: scaleX(1)
                }
            }
        }
        .language{
            float: right;
            margin-top: 4px;
            i{
                cursor: pointer;
                font-size: 20px;
            }
        }
    }
</style>

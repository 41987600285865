<template>
    <div class="home" :class="{ en: language === 'en' }">
        <!-- header -->
        <Header></Header>
        <!-- banner区 -->
        <Banner></Banner>
        <!-- 视频区 -->
        <Videos></Videos>
        <!-- 新闻动态 -->
        <News></News>
        <!-- workspace -->
        <Workspace></Workspace>
        <!-- 数字资产库 -->
        <AssetLibrary></AssetLibrary>
        <!-- cooperation -->
        <Cooperation></Cooperation>
        <!-- Contract -->
        <Contract></Contract>
        <!-- footer -->
        <Footer></Footer>
    </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import Videos from '@/components/Videos.vue'
import News from '@/components/News.vue'
import Workspace from '@/components/Workspace.vue'
import AssetLibrary from '@/components/AssetLibrary.vue'
import Cooperation from '@/components/Cooperation.vue'
import Contract from '@/components/Contract.vue'
import Footer from '@/components/Footer.vue'
import 'wowjs/css/libs/animate.css'
import { WOW } from 'wowjs'
import { mapGetters } from 'vuex'
export default {
    components: {
        Header,
        Banner,
        Videos,
        News,
        Workspace,
        AssetLibrary,
        Cooperation,
        Contract,
        Footer
    },
    computed: {
        ...mapGetters(['language'])
    },
    mounted () {
        this.$nextTick(() => {
            let language = 'en'
            const languageConf = localStorage.getItem('vplab_language')
            if (!languageConf) {
                if (navigator.language.indexOf('zh') > -1) {
                    localStorage.setItem('vplab_language', 'zh-Cn')
                    language = 'zh-Cn'
                } else {
                    localStorage.setItem('vplab_language', 'en')
                }
            } else {
                language = languageConf
            }
            this.$store.commit('SET_LANGUAGE', language)
            var wow = new WOW({
                boxClass: 'wow', // 动画元件css类（默认为wow）
                animateClass: 'animated', // 动画css类（默认为animated）
                offset: 0, // 到元素距离触发动画（当默认为0）
                mobile: true, // 在移动设备上触发动画（默认为true）
                live: true // 对异步加载的内容进行操作（默认为true）
            })
            wow.init()
        })
    }
}
</script>
<style lang="scss">
html {
    scroll-behavior: smooth;
}
</style>
<style lang="scss" scoped>
.home {
    min-width: 1280px;
    background: #000000;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    word-break: break-all;
    &.en {
        font-family: Helvetica Neue, Tahoma, PingFang SC, Microsoft Yahei, Arial,
            Hiragino Sans GB, sans-serif, Apple Color Emoji, Segoe UI Emoji,
            Segoe UI Symbol;
    }
}
</style>
<style lang="scss">
.home {
    .paragraph {
        word-break: break-word;
    }
}
</style>

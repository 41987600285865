<template>
    <div class="virtual_production_contact" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0s">
        <div class="contact_wrapper" :class="{'contact_wrapper-en': language === 'en'}">
            <div class="contact_item" v-if="language === 'zh-Cn'">
                <div class="label">联系人</div>
                <div class='text name'>李小鹏</div>
            </div>
            <div class="contact_item" v-else>
                <div class="label">Contacts</div>
                <div class='text name'>Li Xiaopeng</div>
            </div>
            <div class="contact_item" v-if="language === 'zh-Cn'">
                <div class="label">联系电话</div>
                <div class='text tel'>138-1053-9577</div>
            </div>
            <div class="contact_item" v-else>
                <div class="label">Mobile</div>
                <div class='text tel'>+86 138-1053-9577</div>
            </div>
            <div class="contact_item" v-if="language === 'zh-Cn'">
                <div class="label">邮箱</div>
                <div class='text email'>VPLab@qiyi.com</div>
            </div>
            <div class="contact_item" v-else>
                <div class="label">Mail</div>
                <div class='text email'>VPLab@qiyi.com</div>
            </div>
            <div class="contact_item" v-if="language === 'zh-Cn'">
                <div class="label">地址</div>
                <div class='text address'>北京市朝阳区工人体育场北路21号爱奇艺青春中心</div>
            </div>
            <div class="contact_item" v-else>
                <div class="label">Address</div>
                <div class='text address'>iQIYI Youth Center, 21 North Gongti Rd., Chaoyang District, Beijing.</div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['language'])
    }
}
</script>
<style lang="scss" scoped>
    .virtual_production_contact{
            background: #F7F8FC;
            // height: 134px;
            padding: 40px 0;
            box-sizing: border-box;
            .contact_wrapper{
                width: 1000px;
                margin: auto;
                display: flex;
                justify-content: space-between;
            }
            .contact_item{
                font-size: 14px;
                line-height: 20px;
                .label{
                    font-size: 14px;
                    line-height: 20px;
                    text-align: left;
                    letter-spacing: 0.15em;
                    color: #515154;
                }
                .text{
                    font-size: 16px;
                    line-height: 22px;
                    text-align: left;
                    letter-spacing: 0.15em;
                    color: #1D1D1F;
                    margin-top: 12px;
                    font-weight: 400;
                }
            }
            .contact_item + .contact_item{
                margin-left: 60px;
            }
            .contact_wrapper-en{
                .text{
                    font-size: 12px;
                }
            }
        }
</style>

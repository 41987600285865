<template>
    <div class="virtual_production_workspace" id="workspace" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0s">
        <div class="title" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0.2s">
            <span v-if="language === 'zh-Cn'">爱奇艺虚拟制作摄影棚</span>
            <span v-else>Studio</span>
        </div>
        <div class="subtitle workspace_desc" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0.3s">
            <span v-if="language === 'zh-Cn'" class="paragraph">2022年8月，爱奇艺在浙江横店影视产业园北区建设了2400平米的虚拟制作摄影棚。弧形LED幕墙高8米，直径18米，LED模组支持10比特HDR，经过色彩测试和校准，显示效果细腻、准确。摄影棚另配有多台渲染服务器和编辑工作站，多套摄影机跟踪系统，大型实景转台，智能灯光系统，HDR和SDR监看系统，满足影视级虚拟制作需求。</span>
            <span v-else class="paragraph">In August 2022, iQIYI built a 2400-square-meter virtual production studio in Hengdian, Zhejiang Province. The curved LED screen is 8 meters in height and 18 meters in diameter and supports 10-bit HDR. The studio is equipped with multiple rendering servers and editing workstations, multiple camera tracking systems, large rotating stage, lighting system and HDR monitoring system.</span>
        </div>
        <div class='workspace_pic_list' :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0.5s">
            <div class="pic_list_item">
                <img class='pic_list_item_img' :src="require('@/assets/imgs/list1/air_work2.png')" alt="">
                <div class='pic_list_item_desc'>
                    <span v-if="language === 'zh-Cn'">横店虚拟制作棚全景</span>
                    <span v-else>Panoramic View of Studio</span>
                </div>
            </div>
            <div class="pic_list_item">
                <img class='pic_list_item_img' :src="require('@/assets/imgs/list1/led2.png')" alt="">
                <div class='pic_list_item_desc'>
                    <span v-if="language === 'zh-Cn'">棚内LED屏幕</span>
                    <span v-else>LED Screen</span>
                </div>
            </div>
            <div class="pic_list_item" @click="jumpUrl()" style="cursor:pointer;">
                <img class='pic_list_item_img' v-if="language === 'zh-Cn'" :src="require('@/assets/imgs/list1/map2new_cn.png')" alt="">
                <img class='pic_list_item_img' v-else :src="require('@/assets/imgs/list1/map2new_en.png')" alt="">
                <div class='pic_list_item_desc'>
                    <span v-if="language === 'zh-Cn'">爱奇艺虚拟制作棚位置</span>
                    <span v-else>Location of Studio</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['language'])
    },
    methods: {
        jumpUrl () {
            let url = ''
            if (this.language === 'zh-Cn') {
                url = 'https://j.map.baidu.com/06/fYKh'
            } else {
                url = 'https://j.map.baidu.com/06/fYKh'
            }
            window.open(url)
        }
    }
}
</script>
<style lang="scss" scoped>
.virtual_production_workspace{
            padding-top: 200px;
            box-sizing: border-box;
            .workspace_desc{
                width: 1200px;
                margin: 80px auto 0;
            }
            .workspace_pic_list{
                width: 1200px;
                margin: 70px auto 0;
                box-sizing: border-box;
                text-align: center;
                display: flex;
                justify-content: space-between;
            }
            .title{
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                letter-spacing: 0.3em;
                line-height: 45px;
                text-align: center;
                span{
                    display: inline-block;
                    border-bottom: 1px solid #00DC5A;
                    padding-bottom: 8px;
                }
            }
            .subtitle{
                font-size: 24px;
                line-height: 180%;
                font-weight: 300;
                text-align: left;
                letter-spacing: 0.13em;
            }
            .pic_list_item{
                position: relative;
                width: 384px;
                height: auto;
                display: inline-block;
                .pic_list_item_img{
                    width: 384px;
                    height: 177px;
                }
                .pic_list_item_desc{
                    font-size: 16px;
                    height: 22px;
                    line-height: 22px;
                    margin-top: 16px;
                    text-align: center;
                    letter-spacing: 0.15em;
                }
            }
            .pic_list_item + .pic_list_item{
                margin-left: 24px;
            }
        }
</style>

<template>
    <div class="virtual_production_cooperation" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0s" id="contract">
        <div class="cooperation_main_lineone">
            <div class="cooperation_main_content">
                <div class="cooperation_main_title" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0.2s">
                    <span v-if="language === 'zh-Cn'">诚邀合作</span>
                    <span v-else>Contact Us</span>
                </div>
                <div class="cooperation_main_desc subtitle" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0.3s">
                    <span v-if="language === 'zh-Cn'" class="paragraph">科技发展的浪潮永不停步，爱奇艺虚拟制作业务非常期待与业内艺术家和创作者共同合作，携手前行，充分利用爱奇艺平台优势，推进虚拟制作在内容创作和商业模式上不断创新，为想象插上翅膀，用科技创新让快乐更简单！</span>
                    <span v-else class="paragraph">Technological development never stops, and iQIYI's virtual production team is looking forward to collaborating with artists and creators to promote continuous innovation in virtual production, give wings to imagination, and make happiness simpler through technological innovation.</span>
                </div>
            </div>
        </div>
        <div class="cooperation_main_linetwo">
            <div class="title" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0.2s">
                <span v-if="language === 'zh-Cn'">合作伙伴</span>
                <span v-else>Partners</span>
            </div>
            <div class="imgs" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0.4s">
                <img class="amd" :src="require('@/assets/imgs/indexV2/logo_amd.png')" alt="" @click="jumpUrl('https://www.amd.com/zh-hans/')">
                <img class="nvidia" :src="require('@/assets/imgs/indexV2/logo_nvidia.png')" alt="" @click="jumpUrl('https://www.nvidia.cn/')">
                <img class="lightstar" :src="require('@/assets/imgs/indexV2/logo_lightstar.png')" alt="" @click="jumpUrl('http://www.lightstar.net.cn/')">
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['language'])
    },
    methods: {
        jumpUrl (url) {
            window.open(url)
        }
    }
}
</script>
<style lang="scss" scoped>
    .virtual_production_cooperation{
            background: #fff;
            box-sizing: border-box;
            .cooperation_main_lineone{
                    box-sizing: border-box;
                    display: flex;
                    width: 1200px;
                    margin: auto;
                    padding: 80px 0;
                    .cooperation_main_content{
                        flex: 1;
                        height: 100%;
                        .cooperation_main_title{
                            font-weight: 400;
                            font-size: 40px;
                            letter-spacing: 0.3em;
                            line-height: 67px;
                            text-align: center;
                            color: #000;
                        }
                        .cooperation_main_desc{
                            margin-top: 32px;
                            color: #1D1D1F;
                            font-size: 24px;
                            text-align: left;
                            line-height: 180%;
                        }
                    }
                }
                .cooperation_main_linetwo{
                    background: #000000;
                    padding: 40px 0;
                    .title{
                        text-align: center;
                        font-size: 24px;
                        margin-bottom: 40px;
                        letter-spacing: 0.3em;
                    }
                    .imgs{
                        width: 640px;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        .amd{
                            width: 140px;
                        }
                        .nvidia{
                            width: 200px;
                        }
                        .lightstar{
                            width: 180px;
                        }
                    }
                }
        }
</style>

<template>
    <div class="virtual_production_banner" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0s">
        <div class="bg"></div>
        <div class="content">
            <div class="title" :class="`wow fadeInUp`" data-wow-duration="0.6s" data-wow-delay="0.5s">{{ language === 'zh-Cn' ? `虚拟制作 未来已来` : `The Future Is Now` }}</div>
            <div class="description" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0.8s">
                <template v-if="language === 'zh-Cn'">
                    <div class="paragraph">虚拟制作技术是影视产业赋能制作生产、创新视听形态、重构产业生态的新机遇。爱奇艺作为以科技创新为驱动的内容生产平台，全力推进虚拟制作技术的研发和落地，推动产业变革。</div>
                    <div class="paragraph">爱奇艺虚拟制作提供全方位技术服务和制作监理服务，包括但不限于：方案定制、供应商评估、技术执行、流程管理、成本和质量控制。</div>
                </template>
                <template v-else>
                    <div class="paragraph">iQIYI is fully committed to the development and implementation of virtual production to promote the transformation of the industry. iQIYI Virtual Production provides comprehensive technical and production supervision services, including but not limited to: customized solutions, supplier evaluation, technical execution, process management, cost and quality control.</div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['language'])
    }
}
</script>
<style lang="scss" scoped>
.virtual_production_banner{
        position: relative;
        min-width: 1280px;
        height: 100vh;
        overflow: hidden;
        .bg{
            background: url(../assets/imgs/indexV2/banner.png);
            background-size: cover;
            // width: 100vw;
            // height: 75vw;
            height: calc(100vh - 72px);
            min-width: 800px;
            min-height: 600px;
        }
        img{
            width: 100%;
            object-fit: cover;
        }
        .content{
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: -72px;
        }
        .title{
            width: 100%;
            line-height: 180%;
            letter-spacing: 0.14em;
            font-size: 80px;
            // position: absolute;
            // z-index: 2;
            // top: 15.9vw;
            text-align: center;
        }
        .description{
            margin-top: 60px;
            div{
                font-weight: 300;
                font-size: 24px;
                line-height: 180%;
                letter-spacing: 0.1em;
                width: 1104px;
            }
        }
    }
</style>

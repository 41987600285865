<template>
    <div class="virtual_production_news" id="news" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0s">
        <div class="title" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0.2s">
            <span v-if="language === 'zh-Cn'">动态更新</span>
            <span v-else>Updates</span>
        </div>
        <div class="card-container" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0.4s">
            <div v-for="item in filters" :key="item.id" class="card">
                <img referrerpolicy='no-referrer' class="card-img" :src="item.imageUrl" alt="">
                <div :class="{'card-title-en': language === 'en'}" class="card-title paragraph" v-html="item.title"></div>
                <div class="card-desc paragraph" v-html="item.introduction"></div>
                <div class="goto-detail">
                    <el-button class="btn-goto-detail" @click="jumpFun(item.directUrl)">
                        <span v-if="language === 'zh-Cn'">阅读原文</span>
                        <span v-else>Read More</span>
                        <i class="arrow iconfont icon-yidongduan_jiantou" />
                    </el-button>
                </div>
            </div>
            <template v-if="filters.length % 3 !== 0">
                <div v-for="item in (3 - filters.length % 3)" :key="item" class="card"></div>
            </template>
        </div>
        <template v-if="filters.length > 3">
            <el-pagination
                small
                layout="prev, pager, next"
                :page-size="3"
                @current-change="handlePageChange"
                :total="newsList.length">
            </el-pagination>
        </template>
    </div>
</template>
<script>
import * as configApi from '@/api/config.js'
import { mapGetters } from 'vuex'
export default {
    data: () => {
        return {
            newsList: [],
            filters: [],
            configData: {}
        }
    },
    computed: {
        ...mapGetters(['language']),
        queryType () {
            return this.language === 'zh-Cn' ? 1 : 3
        }
    },
    watch: {
        language: {
            handler (n, o) {
                this.getConfigQuery()
            },
            deep: true
        }
    },
    created () {
        this.getConfig()
    },
    methods: {
        getConfig () {
            configApi.getConfigQuery(1).then(res => {
                if (res.code === 'A00000') {
                    this.configData[1] = res.data
                    if (this.queryType === 1) {
                        this.getConfigQuery()
                    }
                }
            })
            configApi.getConfigQuery(3).then(res => {
                if (res.code === 'A00000') {
                    this.configData[3] = res.data
                    if (this.queryType === 3) {
                        this.getConfigQuery()
                    }
                }
            })
        },
        getConfigQuery () {
            if (this.configData[this.queryType]) {
                this.newsList = []
                this.newsList = this.configData[this.queryType]
                this.filters = this.newsList.slice(0, 3)
            }
        },
        jumpFun (url) {
            window.open(url)
        },
        handlePageChange (p) {
            if (p === 1) {
                this.filters = this.newsList.slice(0, 3)
            } else {
                this.filters = this.newsList.slice(p * 2 - 1, p * 2 + 2)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.virtual_production_news{
    padding-top: 80px;
    .title{
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                letter-spacing: 0.3em;
                line-height: 45px;
                text-align: center;
                margin-bottom: 80px;
                span{
                    display: inline-block;
                    border-bottom: 1px solid #00DC5A;
                    padding-bottom: 8px;
                }
            }
    .card-container{
        display: flex;
        justify-content: center;
        .card{
            width: 384px;
            padding-left: 20px;
            .card-img{
                width: 100%;
                height: 288px;
                object-fit: cover;
            }
            .card-title{
                width: 100%;
                height: 68px;
                font-size: 24px;
                color: #fff;
                margin-top: 20px;
                &-en{
                    height: 102px;
                }
            }
            .card-desc{
                font-size: 16px;
                width: 100%;
                height: 120px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 300;
                color: #FFFFFF;
                margin-top: 20px;
                line-height: 150%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
            }
            .goto-detail{
                width: 384px;
                height: 40px;
                margin: 20px auto 0;
                .btn-goto-detail{
                    font-size: 16px;
                    font-weight: 300;
                    background: transparent;
                    // width: 106px;
                    height: 38px;
                    color: rgba(255, 255, 255, 0.7);
                    border: 1px solid rgba(255, 255, 255, 0.7);
                    border-radius: 2px;
                    &:hover{
                        color: #00DC5A;
                        border: 1px solid #00DC5A;
                    }
                    .arrow{
                        width: 14px;
                        margin-left: 2px;
                    }
                }
            }
        }
    }
    ::v-deep .el-pagination{
        text-align: center;
        margin-top: 60px;
        color: rgba(255, 255, 255, 0.5);
        button, li{
            background: transparent;
            font-size: 16px;
        }
        button{
            color: rgba(255, 255, 255, 0.5)!important;
        }
        .active{
            color: #fff;
            font-size: 16px;
            border-bottom: 1px solid #00DC5A;
            padding-bottom: 8px;
            display: inline-block;
        }
    }
}
</style>

<template>
    <div class="virtual_production_footer" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0s">
        <span v-if="language === 'zh-Cn'">Copyright © {{year}} 爱奇艺 All Rights Reserved</span>
        <span v-else>Copyright © {{year}} iQIYI All Rights Reserved</span>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data: () => {
        return {
            year: new Date().getFullYear()
        }
    },
    computed: {
        ...mapGetters(['language'])
    }
}
</script>
<style lang="scss" scoped>
.virtual_production_footer{
            width: 100%;
            height: 80px;
            line-height: 80px;
            text-align: center;
            font-size: 14px;
            color: #D3D6E6;
            font-family: Helvetica Neue, Tahoma, PingFang SC,Microsoft Yahei, Arial, Hiragino Sans GB, sans-serif, Apple Color Emoji,Segoe UI Emoji, Segoe UI Symbol;
        }
</style>

<template>
    <div class="virtual_production_digital_asset_library" id="digital_asset_library" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0s">
        <div class="title" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0.2s">
            <span v-if="language === 'zh-Cn'">数字资产库</span>
            <span v-else>Digital Asset Library</span>
        </div>
        <div class="pic" :class="`wow fadeIn`" data-wow-duration="0.8s" data-wow-delay="0.4s">
            <el-carousel :interval="10000">
                <el-carousel-item v-for="item, index in assetList" :key="`swiper2${index}`">
                    <img referrerpolicy='no-referrer' :src="item.coverUrl" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="desc" :class="`wow fadeInUp`" data-wow-duration="0.8s" data-wow-delay="0.3s">
            <span v-if="language === 'zh-Cn'" class="paragraph">爱奇艺从内容生产需求出发，打造以“爱奇艺数字资产库”为核心的数字资产服务平台，包含3D模型、动作、全景视频等多类资产，为虚拟制作提供充足的资源保障。同时，爱奇艺自研虚拟拍摄预演软件iViz，辅助主创进行拍摄前预演，精准控制拍摄效果；开发包含64个机位的奇拍自由视角系统，实现“子弹时间”效果的快速部署、拍摄和编辑。</span>
            <span v-else class="paragraph">To support virtual production, iQIYI has built a "Digital Asset Library" to include various assets such as 3D models, motion capture data, and panoramic videos. At the same time, iQIYI has developed its own pre-visualization software "iViz" and the "QiPai Free View System", which includes 64 cameras and enables the fast shooting and editing of the "bullet time" effect.</span>
        </div>
        <div class="goto-detail">
            <el-button class="btn-goto-detail" @click="jumpFun()">
                <span v-if="language === 'zh-Cn'">去看看</span>
                <span v-else>Browse Assets</span>
                <img class="arrow" src="@/assets/imgs/indexV2/detail_arrow.png" alt="">
            </el-button>
        </div>
    </div>
</template>
<script>
import * as configApi from '@/api/config.js'
import { mapGetters } from 'vuex'
export default {
    data: () => {
        return {
            assetList: [],
            configData: {}
        }
    },
    computed: {
        ...mapGetters(['language']),
        queryType () {
            return this.language === 'zh-Cn' ? 2 : 4
        }
    },
    watch: {
        language: {
            handler (n, o) {
                this.getConfigQuery()
            },
            deep: true
        }
    },
    methods: {
        getConfig () {
            configApi.getConfigQuery(2).then(res => {
                if (res.code === 'A00000') {
                    this.configData[2] = res.data
                    if (this.queryType === 2) {
                        this.getConfigQuery()
                    }
                }
            })
            configApi.getConfigQuery(4).then(res => {
                if (res.code === 'A00000') {
                    this.configData[4] = res.data
                    if (this.queryType === 4) {
                        this.getConfigQuery()
                    }
                }
            })
        },
        getConfigQuery () {
            if (this.configData[this.queryType]) {
                this.assetList = []
                this.configData[this.queryType].forEach(item => {
                    this.assetList.push({
                        coverUrl: item.imageUrl,
                        title: ''
                    })
                })
            }
        },
        jumpFun () {
            window.open('https://asset.iqiyi.com/home')
        }
    },
    created () {
        this.getConfig()
    }
}
</script>
<style lang="scss" scoped>
.virtual_production_digital_asset_library{
    padding-top: 200px;
    padding-bottom: 108px;
            .title{
                font-style: normal;
                font-weight: 400;
                font-size: 40px;
                letter-spacing: 0.3em;
                line-height: 45px;
                text-align: center;
                span{
                    display: inline-block;
                    border-bottom: 1px solid #00DC5A;
                    padding-bottom: 8px;
                }
            }
            .pic{
                width: 1200px;
                height: 521px;
                margin: 80px auto 0;
                ::v-deep .el-carousel__container{
                    width: 100%;
                    height: 521px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .desc{
                font-weight: 300;
                font-size: 24px;
                width: 1213px;
                letter-spacing: 0.1em;
                line-height: 180%;
                margin: 80px auto 0;
                color: #fff;
            }
            .goto-detail{
                width: 1200px;
                height: 40px;
                margin: 20px auto 0;
                .btn-goto-detail{
                    font-size: 16px;
                    font-weight: 400;
                    background: transparent;
                    // width: 106px;
                    height: 38px;
                    color: rgba(255, 255, 255, 0.7);
                    border: 1px solid rgba(255, 255, 255, 0.7);
                    border-radius: 2px;
                    &:hover{
                        color: #00DC5A;
                        border: 1px solid #00DC5A;
                    }
                    .arrow{
                        width: 14px;
                        vertical-align: middle;
                        margin-left: 4px;
                    }
                }
            }
        }
</style>
